const posts = [
  {
    id: 1,
    slug: "post-1",
    year: "1998",
    publisher: "Robert Friedrich",
    printed_country: "China",
    illustrator: "Gwynedd M. Hudson",
    ISBN: "0 90778 572 7",
    price: "5",
    purchased: "KW Books",
    condition: "Good",
    fact: "The book binding on this is beautiful, but the art is low-key terrifying.",
  },
  {
    id: 2,
    slug: "post-2",
    year: "1986",
    publisher: "Chancellor Press",
    printed_country: "Portugal",
    illustrator: "Arthur Rackham",
    ISBN: "0 907486 79 7",
    price: "7.50",
    purchased: "KW Books",
    condition: "Good",
    fact: "Under the ugly dust jacket, the book is beautiful.",
  },
  {
    id: 3,
    slug: "post-3",
    year: "1991",
    publisher: "Unicorn Publishing House",
    printed_country: "Unknown",
    illustrator: "Greg Hildebrandt",
    ISBN: "Unknown",
    price: "1.99",
    purchased: "Value Village",
    condition: "Good",
    fact: "This version must have been popular because I've seen it in a few places.",
  },
  {
    id: 4,
    slug: "post-4",
    year: "90's",
    publisher: "Dell Yearling",
    printed_country: "Unknown",
    illustrator: "Unknown",
    ISBN: "0 71009 01400",
    price: "2.49",
    purchased: "Value Village",
    condition: "Good",
    fact: "It's a pop-up book!",
  },
  {
    id: 5,
    slug: "post-5",
    year: "1994",
    publisher: "Bracken Books",
    printed_country: "Singapore",
    illustrator: "John Tenniel",
    ISBN: "1 85170 109 5",
    price: "4.99",
    purchased: "Value Village",
    condition: "Good",
    fact: "It's still the Tenniel art, but the way they laid it out is stunning.",
  },
  {
    id: 6,
    slug: "post-6",
    year: "1990",
    publisher: "Troll Associates",
    printed_country: "Unknown",
    illustrator: "Katheryn A. Couri",
    ISBN: "0 8167 1862 8",
    price: "3",
    purchased: "KW Books",
    condition: "Good",
    fact: "The art in this one is really unique.",
  },
  {
    id: 7,
    slug: "post-7",
    year: "1992",
    publisher: "Norton London",
    printed_country: "Unknown",
    illustrator: "John Tenniel",
    ISBN: "0 393 95804 3",
    price: "4.99",
    purchased: "Value Village",
    condition: "Good",
    fact: "I love the cover art on this.",
  },
  {
    id: 8,
    slug: "post-8",
    year: "2010",
    publisher: "Pippbrook Books",
    printed_country: "Unknown",
    illustrator: "Cinzia Ratto",
    ISBN: "978 1 84011 438 6",
    price: "8",
    purchased: "Second Look Books",
    condition: "Good",
    fact: "This is a really unique version of Alice.",
  },
  {
    id: 9,
    slug: "post-9",
    year: "1990",
    publisher: "The Jabberwock",
    printed_country: "Unknown",
    illustrator: "John Tenniel",
    ISBN: "Unknown",
    price: "50",
    purchased: "Market Seller",
    condition: "New - Signed",
    fact: "This is a rare find. Signed by the compiler of the book.",
  },
  {
    id: 10,
    slug: "post-10",
    year: "1998",
    publisher: "Wings Books",
    printed_country: "Unknown",
    illustrator: "John Tenniel",
    ISBN: " 0517 18920 8",
    price: "20",
    purchased: "Old Goat Books",
    condition: "Good",
    fact: "This is a really heavy copy.",
  },
  {
    id: 11,
    slug: "post-11",
    year: "2006",
    publisher: "Random House",
    printed_country: "Unknown",
    illustrator: "John Tenniel",
    ISBN: " 0517 18920 8",
    price: "5.50",
    purchased: "KW Books",
    condition: "Good",
    fact: "This one doesn't have much going on.",
  },
  {
    id: 12,
    slug: "post-12",
    year: "2008",
    publisher: "Harper Collins",
    printed_country: "China",
    illustrator: "Mary Engelbreit",
    ISBN: "Unknown",
    price: "2.49",
    purchased: "Value Village",
    condition: "Good",
    fact: "This one is really cute. Very cutesy drawing style.",
  },
  {
    id: 13,
    slug: "post-13",
    year: "2015",
    publisher: "Puffin Books",
    printed_country: "China",
    illustrator: "Anna Bond",
    ISBN: "978 014 751587 2",
    price: "20",
    purchased: "Amazon - Used",
    condition: "Good",
    fact: "This is a beautiful version. And probably my favorite.",
  },
  {
    id: 14,
    slug: "post-14",
    year: "2017",
    publisher: "Puffin Books and Pantone",
    printed_country: "USA",
    illustrator: "not illustrated",
    ISBN: "978 0 425 28928",
    price: "6",
    purchased: "KW Books",
    condition: "Poor",
    fact: "This one was definitely bought for a school project. It's in rough shape.",
  },
  {
    id: 15,
    slug: "post-15",
    year: "1994",
    publisher: "Penguin Books",
    printed_country: "Great Britain",
    illustrator: "John Tenniel",
    ISBN: "0 14062 086 9",
    price: "6",
    purchased: "KW Books",
    condition: "Good",
    fact: "Another basic version.",
  },
  {
    id: 16,
    slug: "post-16",
    year: "2012",
    publisher: "Penguin Books",
    printed_country: "unknown",
    illustrator: "John Tenniel",
    ISBN: " 978 0 141 19968 9",
    price: "6",
    purchased: "KW Books",
    condition: "Good",
    fact: "A really basic version.",
  },
  {
    id: 17,
    slug: "post-17",
    year: "1991",
    publisher: "Baronet Books",
    printed_country: "USA",
    illustrator: "Lea Castor",
    ISBN: "0 86611 873",
    price: "5",
    purchased: "KW Books",
    condition: "Good",
    fact: "There is a note inside thanking a teacher. It's very sweet.",
  },
  {
    id: 18,
    slug: "post-18",
    year: "1993",
    publisher: "Dover Publications",
    printed_country: "USA",
    illustrator: "John Tenniel",
    ISBN: "0 486 27543 4",
    price: "2",
    purchased: "KW Books",
    condition: "Good",
    fact: "Might be my favorite cover.",
  },
  {
    id: 19,
    slug: "post-19",
    year: "1967",
    publisher: "Macmillan and Company",
    printed_country: "Great Britain",
    illustrator: "John Tenniel",
    ISBN: "Unknown",
    price: "10",
    purchased: "KW Books",
    condition: "Good",
    fact: "This one is in Latin.",
  },
  {
    id: 20,
    slug: "post-20",
    year: "2009",
    publisher: "Parragon",
    printed_country: "China",
    illustrator: "June Goulding",
    ISBN: "978 1 4054 1954 3",
    purchased: "Value Village",
    price: "4.99",
    condition: "Good",
    fact: "This is a very heavy copy.",
  },
  {
    id: 21,
    slug: "post-21",
    year: "1989",
    publisher: "Century Hutchinson",
    printed_country: "Great Britain",
    illustrator: "Peter Weevers",
    ISBN: "0 09 173764 8",
    purchased: "Value Village",
    price: "4.99",
    condition: "Excellent",
    fact: "Stunning illustrations.",
  },
  {
    id: 22,
    slug: "post-22",
    year: "1978",
    publisher: "Wonder Books",
    printed_country: "United States",
    illustrator: "Unknown",
    ISBN: "Unknown",
    purchased: "Value Village",
    price: "1.79",
    condition: "Poor",
    fact: "A small child definitely chewed on this and I still bought it... ",
  },
  {
    id: 23,
    slug: "post-23",
    year: "1999",
    publisher: "Kingfisher",
    printed_country: "Hong Kong",
    illustrator: "John Tenniel",
    ISBN: "0 7534 5207 3",
    purchased: "Value Village",
    price: "5.49",
    condition: "Excellent",
    fact: "Alice's Adventures in Wonderland is less than 10 pages of this giant book. Pages 103 - 110.",
  },
  {
    id: 24,
    slug: "post-24",
    year: "2017",
    publisher: "Miles Kelly",
    printed_country: "China",
    illustrator: "Martina Peluso",
    ISBN: "978 1 78617 186 3",
    purchased: "Amazon - Used",
    price: "23",
    condition: "Good",
    fact: "This book looks way better without the dust jacket",
  },
  {
    id: 25,
    slug: "post-25",
    year: "2018",
    publisher: "Paper Mill Press",
    printed_country: "China",
    illustrator: "John Tenniel",
    ISBN: "978 1 926444 26 0",
    purchased: "Book Outlet Canada",
    price: "5.99",
    condition: "New",
    fact: "This version is part of a set of 43 classic books.",
  },
];

export default posts;
